import { useEffect, useRef } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal, Table } from "antd";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
import { useProjectDetailsStore } from "modules/organization/store";
import { useRbac } from "auth/rbac/rbac";

const DeleteQuestionModal = () => {
  const { 
    showDeleteQuestionModal, 
    questionInfo, 
    setShowDeleteQuestionModal, 
    deleteQuestionRow, 
  } = useImporterToolStore();

  const { projectDetails } = useProjectDetailsStore();
  const { userDetails } = useRbac();


  const okButtonRef = useRef<HTMLButtonElement>(null);
  const headingRef = useRef<HTMLParagraphElement>(null);

  const renderQuestionRender = () =>{
    const questions = [questionInfo].map(each => {
        return {
            key: each.key,
            title: each.question_description,
            layer_string: each.question_order,
        }
    });
    const columns = [
        {
          title: 'Question',
          dataIndex: 'title',
          key: 'name',
          render: (question: React.ReactNode, rest: {key: string, layer_string: string}) =>  <div key={rest.key}>Q{rest.layer_string} - {question}</div>,
          width: 150,
        },
    ];

    return <Table className="scrollableTable" columns={columns} dataSource={questions} showHeader={false} pagination={false} scroll={{ y: 300 }}/>
}

  const doDeleteAQuestion = async () => {
    setShowDeleteQuestionModal(false);
    deleteQuestionRow(projectDetails, userDetails);
    message.success("Question deleted successfully")

  };

  useEffect(() => {
    setTimeout(() => {
      headingRef.current?.focus();
      headingRef.current?.click();
    }, 1000)

  }, [])

  return (
    <Modal
    className="confirmationModal"
    title={<>Delete Question</>} 
    centered
    open={showDeleteQuestionModal} 
    onCancel={()=>setShowDeleteQuestionModal(false)}
    footer={[
        <Button key="yes" onClick={()=> setShowDeleteQuestionModal(false)}>
        Cancel
        </Button>,
        <Button key="no" type="primary" onClick={doDeleteAQuestion} ref={okButtonRef}>
        Delete Question
        </Button>,
    ]}
    >
        <div className="notificationTxtWrap">
            <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
            <div className="notificationTxt" tabIndex={-1} ref={headingRef}>
                <p>Are you sure you want to delete this question?</p>
                <p style={{fontStyle:'italic'}}>* This process cannot be undone</p>
            </div>
        </div>
        {renderQuestionRender()}
</Modal>
  );
};

export default DeleteQuestionModal;