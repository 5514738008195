import React, { useEffect, useMemo } from "react";
import { Steps, StepProps, Button, Result } from "antd";
import { LoadingOutlined, StopOutlined } from "@ant-design/icons";
import { FirebaseService } from "modules/application/services";
import { useUserStore } from "modules/shared/store";
import { useProjectDetailsStore } from "modules/organization/store";

const StatusUpdate: React.FC<{
  status: string;
  projectId: number;
  projectSlug: string;
  isStaging: boolean;
  failedReason: string;
}> = ({ status, projectId, projectSlug, isStaging, failedReason }) => {
  const firebaseService = useMemo(() => new FirebaseService(), []);
  const { userDetails } = useUserStore();
  const { getProjectDetails } = useProjectDetailsStore();

  const stepStatuses =  isStaging ? ["extracting", "transforming", "loading"] : ["preparing", "formatting", "importing"];
  const stepStatusText = isStaging ? ["Extracting", "Processing", "Finalizing"] : ["Preparing", "Formatting", "Importing"];
  const stepDescriptions = isStaging ? [
    "Extracting data from the uploaded excel file",
    "Processing data from the extracted data",
    "Finalizing data from the processed data",
  ] : [
    "Preparing data to import into the project",
    "Structuring data to import into the project",
    "Importing data into the project"
  ];

  const currentStepIndex = stepStatuses.indexOf(status);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userDetails?.org_id && userDetails?.id) {
      unsubscribe = firebaseService.listenForNotifications(
        userDetails.org_id,
        userDetails.id,
        (notification) => {
          if (
            notification[0].id.startsWith("Importer") &&
            projectId === parseInt((notification[0].id).split("_")[1])
          ) {
            getProjectDetails(projectSlug).catch((error) => console.log(error));
          }
        }
      );
    }

    return () => {
      unsubscribe();
    };
  }, []);

  const steps: StepProps[] = stepStatuses.map((_, index) => ({
    title: `${stepStatusText[index]} Data`,
    description: stepDescriptions[index],
    icon: currentStepIndex === index ? <LoadingOutlined /> : undefined,
  }));

  if(status.endsWith("failed")) {
    return (
      <div className="verifyMsgWrap" style={{minHeight: "64vh"}}>
        <Result
          icon={<StopOutlined style={{color: 'var(--mnq-primary'}}/>}
          title={`Failed to ${status === 'staging_failed' ? 'stage' : 'import'} data`}
          extra={<p style={{color: 'var(--mnq-primary'}}>{failedReason}</p>}
        />
      </div>
    );
  }

  return (
    <div style={{ height: "64vh" }}>
      <Steps
        className="importerStatusSteps"
        current={currentStepIndex}
        items={steps}
      />
    </div>
  );
};

export default StatusUpdate;
